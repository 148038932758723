/*Falha do Sistema Respiratorio*/

#Respiratorio8-1a{
    display: none;
}
#Respiratorio8-1b{
    display: none;
}

#Respiratorio8-2a{
    display: none;
}

#Respiratorio8-2b{
    display: none;
}

#Respiratorio8-2c{
    display: none;
}


#Respiratorio8-3a{
    display: none;
}

#Respiratorio8-3b{
    display: none;
}

#Respiratorio8-3c{
    display: none;
}

#Respiratorio8-3d{
    display: none;
}

#Respiratorio8-4a{
    display: none;
}

#Respiratorio8-4b{
    display: none;
}

#Respiratorio8-5a{
    display: none;
}

#Respiratorio8-5b{
    display: none;
}

#Respiratorio8-5c{
    display: none;
}

#Respiratorio8-6a{
    display: none;
}

#Respiratorio8-6b{
    display: none;
}

#Respiratorio8-6c{
    display: none;
}


#Respiratorio8-7a{
    display: none;
}

#Respiratorio8-7b{
    display: none;
}

#Respiratorio8-8a{
    display: none;
}

#Respiratorio8-8b{
    display: none;
}

#Respiratorio8-8c{
    display: none;
}

#Respiratorio8-8d{
    display: none;
}

#Respiratorio8-9a{
    display: none;
}

#Respiratorio8-9b{
    display: none;
}

#Respiratorio8-9c{
    display: none;
}

#Respiratorio8-10a{
    display: none;
}

#Respiratorio8-10b{
    display: none;
}

#Respiratorio8-10c{
    display: none;
}

#Respiratorio8-10d{
    display: none;
}

#Respiratorio8-10e{
    display: none;
}

#Respiratorio8-11a{
    display: none;
}

#Respiratorio8-11b{
    display: none;
}


/*Aprisionamento de Mergulhador no Fundo*/
/*AprisonamentoMergulhador1-1b*/
#AprisonamentoMergulhador4-1c{
    display:none;
}

/*#AprisonamentoMergulhador1-1g*/
#AprisonamentoMergulhador4-1h{
    display:none;
}

/*#AprisonamentoMergulhador1-4a*/
#AprisonamentoMergulhador2-2a,#AprisonamentoMergulhador4-2a{
    display:none;
}

/*#AprisonamentoMergulhador1-4b*/
#AprisonamentoMergulhador2-2b,#AprisonamentoMergulhador4-2b{
    display:none;
}

/*#AprisonamentoMergulhador1-4c*/
#AprisonamentoMergulhador2-2c{
    display:none;
}
/*#AprisonamentoMergulhador1-4d*/
#AprisonamentoMergulhador2-2d{
    display:none;
}

/*#AprisonamentoMergulhador1-4e*/
#AprisonamentoMergulhador2-2e,#AprisonamentoMergulhador4-2c{
    display:none;
}

/*#AprisonamentoMergulhador1-4f*/
#AprisonamentoMergulhador2-2f{
    display:none;
}

/*#AprisonamentoMergulhador1-4g*/
#AprisonamentoMergulhador2-2g{
    display:none;
}

/*#AprisonamentoMergulhador1-5a*/
#AprisonamentoMergulhador5-1a{
    display:none;
}

/*#AprisonamentoMergulhador2-1a*/
#AprisonamentoMergulhador3-1b{
    display:none;
}

/*#AprisonamentoMergulhador2-1b*/
#AprisonamentoMergulhador3-1c{
    display:none;
}

/*#AprisonamentoMergulhador2-3e*/
#AprisonamentoMergulhador2-3h,#AprisonamentoMergulhador4-3e{
    display:none;
}

/*#AprisonamentoMergulhador6-6b*/
#AprisonamentoMergulhador7-6b{
    display:none;
}

/*#AprisonamentoMergulhador8-1b*/
#AprisonamentoMergulhador9-1b,#AprisonamentoMergulhador10-1b{
    display:none;
}


/*#AprisonamentoMergulhador8-1c*/
#AprisonamentoMergulhador9-1c,#AprisonamentoMergulhador10-1c{
    display:none;
}

/*#AprisonamentoMergulhador8-1d*/
#AprisonamentoMergulhador9-1d,#AprisonamentoMergulhador10-1d{
    display:none;
}

/*#AprisonamentoMergulhador8-4a*/
#AprisonamentoMergulhador9-4a,#AprisonamentoMergulhador10-4a{
    display:none;
}

/*#AprisonamentoMergulhador8-4b*/
#AprisonamentoMergulhador9-4b,#AprisonamentoMergulhador10-4b{
    display:none;
}

/*#AprisonamentoMergulhador8-4c*/
#AprisonamentoMergulhador10-4c{
    display:none;
}

/*#AprisonamentoMergulhador8-5a*/
#AprisonamentoMergulhador9-5a,#AprisonamentoMergulhador10-5a{
    display:none;
}

/*#AprisonamentoMergulhador8-5b*/
#AprisonamentoMergulhador9-5b,#AprisonamentoMergulhador10-5b{
    display:none;
}

/*#AprisonamentoMergulhador8-5c*/
#AprisonamentoMergulhador10-5c{
    display:none;
}

/*#AprisonamentoMergulhador8-5d*/
#AprisonamentoMergulhador9-5d{
    display:none;
}

/*#AprisonamentoMergulhador8-6a*/
#AprisonamentoMergulhador9-6a,#AprisonamentoMergulhador10-6a{
    display:none;
}


/*#AprisonamentoMergulhador8-6b*/
#AprisonamentoMergulhador9-6b,#AprisonamentoMergulhador10-6b{
    display:none;
}

/*#AprisonamentoMergulhador8-7a*/
#AprisonamentoMergulhador9-7a,#AprisonamentoMergulhador10-7a{
    display:none;
}

/*#AprisonamentoMergulhador8-7b*/
#AprisonamentoMergulhador9-7b,#AprisonamentoMergulhador10-7b{
    display:none;
}

/*#AprisonamentoMergulhador8-8a*/
#AprisonamentoMergulhador9-8a,#AprisonamentoMergulhador10-8a{
    display:none;
}

/*#AprisonamentoMergulhador8-8c*/
#AprisonamentoMergulhador9-8c,#AprisonamentoMergulhador10-8c{
    display:none;
}

/*#AprisonamentoMergulhador8-9a*/
#AprisonamentoMergulhador9-9a,#AprisonamentoMergulhador10-9a{
    display:none;
}

/*#AprisonamentoMergulhador8-9b*/
#AprisonamentoMergulhador9-9b,#AprisonamentoMergulhador10-9b{
    display:none;
}

/*#AprisonamentoMergulhador8-9c*/
#AprisonamentoMergulhador9-9c,#AprisonamentoMergulhador10-9c{
    display:none;
}

/*#AprisonamentoMergulhador8-9e*/
#AprisonamentoMergulhador9-9e,#AprisonamentoMergulhador10-9e{
    display:none;
}

/*#AprisonamentoMergulhador9-1a*/
#AprisonamentoMergulhador10-1a{
    display:none;
}

/*#AprisonamentoMergulhador9-3d*/
#AprisonamentoMergulhador10-2g,#AprisonamentoMergulhador10-3d{
    display:none;
}

/*#AprisonamentoMergulhador10-2d*/
#AprisonamentoMergulhador10-3a{
    display:none;
}

/*#AprisonamentoMergulhador10-2e*/
#AprisonamentoMergulhador10-3b{
    display:none;
}

/******Exposição Inadequada Mergulhador********/

/*#ExposicaoInadequadaMergulhador1-6b29*/
#ExposicaoInadequadaMergulhador2-6b{
display:none;
}

/*#ExposicaoInadequadaMergulhador1-6d31*/
#ExposicaoInadequadaMergulhador5-5d,#ExposicaoInadequadaMergulhador6-5d,#ExposicaoInadequadaMergulhador7-5d{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-1a58*/
#ExposicaoInadequadaMergulhador6-1a,#ExposicaoInadequadaMergulhador7-1a{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-1d61*/
#ExposicaoInadequadaMergulhador6-1d,#ExposicaoInadequadaMergulhador7-1d{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-1b59*/
#ExposicaoInadequadaMergulhador6-1b,#ExposicaoInadequadaMergulhador7-1b{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-1f63*/
#ExposicaoInadequadaMergulhador6-1f,#ExposicaoInadequadaMergulhador7-1f{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-2a64*/
#ExposicaoInadequadaMergulhador6-2a,#ExposicaoInadequadaMergulhador7-2a{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-2b65*/
#ExposicaoInadequadaMergulhador6-2b,#ExposicaoInadequadaMergulhador7-2b{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-2c66*/
#ExposicaoInadequadaMergulhador7-2c{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-2d67*/
#ExposicaoInadequadaMergulhador6-2d,#ExposicaoInadequadaMergulhador7-3a{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-3a68*/
#ExposicaoInadequadaMergulhador6-3a,#ExposicaoInadequadaMergulhador7-2b{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-3b69*/
#ExposicaoInadequadaMergulhador6-3b,#ExposicaoInadequadaMergulhador7-2c{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-3c70*/
#ExposicaoInadequadaMergulhador6-3c,#ExposicaoInadequadaMergulhador7-3a{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-3d71*/
#ExposicaoInadequadaMergulhador6-3d,#ExposicaoInadequadaMergulhador7-3b{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-4a72*/
#ExposicaoInadequadaMergulhador6-4a,#ExposicaoInadequadaMergulhador7-4a{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-4b73*/
#ExposicaoInadequadaMergulhador6-4b,#ExposicaoInadequadaMergulhador7-4b{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-4c74*/
#ExposicaoInadequadaMergulhador6-4c,#ExposicaoInadequadaMergulhador7-4c{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-5a75*/
#ExposicaoInadequadaMergulhador6-5a,#ExposicaoInadequadaMergulhador7-5a{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-5b76*/
#ExposicaoInadequadaMergulhador6-5b,#ExposicaoInadequadaMergulhador7-5b{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-5c77*/
#ExposicaoInadequadaMergulhador6-5c,#ExposicaoInadequadaMergulhador7-5c{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-6a79*/
#ExposicaoInadequadaMergulhador6-6a,#ExposicaoInadequadaMergulhador7-6a{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-6b80*/
#ExposicaoInadequadaMergulhador6-6b,#ExposicaoInadequadaMergulhador7-6b{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-7a81*/
#ExposicaoInadequadaMergulhador6-7a,#ExposicaoInadequadaMergulhador7-7a{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-7b82*/
#ExposicaoInadequadaMergulhador6-7b,#ExposicaoInadequadaMergulhador7-7b{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-8a85*/
#ExposicaoInadequadaMergulhador6-8a,#ExposicaoInadequadaMergulhador7-8a{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-8b86*/
#ExposicaoInadequadaMergulhador6-8b,#ExposicaoInadequadaMergulhador7-8b{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-8c87*/
#ExposicaoInadequadaMergulhador6-8c,#ExposicaoInadequadaMergulhador7-8c{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-9a88*/
#ExposicaoInadequadaMergulhador6-9a,#ExposicaoInadequadaMergulhador7-9a{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-9b89*/
#ExposicaoInadequadaMergulhador6-9b,#ExposicaoInadequadaMergulhador7-9b{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-9c90*/
#ExposicaoInadequadaMergulhador6-9c,#ExposicaoInadequadaMergulhador7-9c{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-9d91*/
#ExposicaoInadequadaMergulhador6-9d,#ExposicaoInadequadaMergulhador7-9d{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-9e92*/
#ExposicaoInadequadaMergulhador6-9e,#ExposicaoInadequadaMergulhador7-9e{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-10a93*/
#ExposicaoInadequadaMergulhador6-10a{
    display:none;
}

/*#ExposicaoInadequadaMergulhador5-10b94*/
#ExposicaoInadequadaMergulhador6-10b{
    display:none;
}

/****Incendio Explosao Camara***/


/*#IncendioExplosaoCamara7-1a72*/

#IncendioExplosaoCamara8-1b,#IncendioExplosaoCamara9-1a{
    display:none;
}

/*#IncendioExplosaoCamara7-1b73*/

#IncendioExplosaoCamara8-1c,#IncendioExplosaoCamara9-1b{
    display:none;
}

/*#IncendioExplosaoCamara7-1c74*/

#IncendioExplosaoCamara8-1d,#IncendioExplosaoCamara9-1c{
    display:none;
}

/*#IncendioExplosaoCamara7-3a77*/

#IncendioExplosaoCamara8-2a{
    display:none;
}

/*#IncendioExplosaoCamara7-3b78*/

#IncendioExplosaoCamara8-2b{
    display:none;
}

/*#IncendioExplosaoCamara7-3b79*/

#IncendioExplosaoCamara8-2c{
    display:none;
}

/*#IncendioExplosaoCamara7-8a94*/

#IncendioExplosaoCamara8-3a{
    display:none;
}

/*#IncendioExplosaoCamara7-8b95*/

#IncendioExplosaoCamara8-3b{
    display:none;
}

/*#IncendioExplosaoCamara7-9a96*/

#IncendioExplosaoCamara8-4a,#IncendioExplosaoCamara9-2a{
    display:none;
}

/*#IncendioExplosaoCamara7-9b97*/

#IncendioExplosaoCamara8-4b,#IncendioExplosaoCamara9-2b{
    display:none;
}

/*#IncendioExplosaoCamara7-9c98*/

#IncendioExplosaoCamara8-4c,#IncendioExplosaoCamara9-2c{
    display:none;
}

/*#IncendioExplosaoCamara7-10a99*/

#IncendioExplosaoCamara8-5a,#IncendioExplosaoCamara9-3a{
    display:none;
}

/*#IncendioExplosaoCamara7-10b100*/

#IncendioExplosaoCamara8-5b,#IncendioExplosaoCamara9-3b{
    display:none;
}

/*#IncendioExplosaoCamara7-10c101*/

#IncendioExplosaoCamara8-5c,#IncendioExplosaoCamara9-3c{
    display:none;
}

/*#IncendioExplosaoCamara7-10d102*/

#IncendioExplosaoCamara8-5d,#IncendioExplosaoCamara9-3d{
    display:none;
}

/*#IncendioExplosaoCamara7-11a103*/

#IncendioExplosaoCamara8-6a,#IncendioExplosaoCamara9-4a{
    display:none;
}

/*#IncendioExplosaoCamara7-11b104*/

#IncendioExplosaoCamara8-6b,#IncendioExplosaoCamara9-4b{
    display:none;
}

/*#IncendioExplosaoCamara7-11c105*/

#IncendioExplosaoCamara8-6c,#IncendioExplosaoCamara9-4c{
    display:none;
}

/*#IncendioExplosaoCamara7-11d106*/

#IncendioExplosaoCamara8-6d,#IncendioExplosaoCamara9-4d{
    display:none;
}

/*#IncendioExplosaoCamara7-12a107*/

#IncendioExplosaoCamara8-7a,#IncendioExplosaoCamara9-5a{
    display:none;
}

/*#IncendioExplosaoCamara7-12b108*/

#IncendioExplosaoCamara8-7b,#IncendioExplosaoCamara9-5b{
    display:none;
}

/*#IncendioExplosaoCamara7-13a109*/

#IncendioExplosaoCamara8-8a,#IncendioExplosaoCamara9-6a{
    display:none;
}

/*#IncendioExplosaoCamara7-13b110*/

#IncendioExplosaoCamara8-8b,#IncendioExplosaoCamara9-6b{
    display:none;
}

/*#IncendioExplosaoCamara7-14a111*/

#IncendioExplosaoCamara8-9a,#IncendioExplosaoCamara9-7a{
    display:none;
}


/*#IncendioExplosaoCamara7-14b112*/

#IncendioExplosaoCamara8-9b,#IncendioExplosaoCamara9-7b{
    display:none;
}

/*#IncendioExplosaoCamara7-14c113*/

#IncendioExplosaoCamara8-9c,#IncendioExplosaoCamara9-7c{
    display:none;
}

/*#IncendioExplosaoCamara7-15a114*/

#IncendioExplosaoCamara8-10a,#IncendioExplosaoCamara9-10a{
    display:none;
}

/*#IncendioExplosaoCamara7-15b115*/

#IncendioExplosaoCamara8-10b,#IncendioExplosaoCamara9-10b{
    display:none;
}

/*#IncendioExplosaoCamara7-15c116*/

#IncendioExplosaoCamara8-10c,#IncendioExplosaoCamara9-10c{
    display:none;
}

/*#IncendioExplosaoCamara7-15d117*/

#IncendioExplosaoCamara8-10d,#IncendioExplosaoCamara9-10d{
    display:none;
}

/*#IncendioExplosaoCamara7-16a118*/

#IncendioExplosaoCamara8-11a,#IncendioExplosaoCamara9-11a{
    display:none;
}

/*#IncendioExplosaoCamara7-16b119*/

#IncendioExplosaoCamara8-11b,#IncendioExplosaoCamara9-11b{
    display:none;
}

/****Falha Equipamento Individual****/

/*#FalhaEquipamentoIndividual1-3b8*/

#FalhaEquipamentoIndividual2-3b,#FalhaEquipamentoIndividual3-1b{
    display:none;
}

/*#FalhaEquipamentoIndividual1-3c9*/

#FalhaEquipamentoIndividual2-3c{
    display:none;
}


/*#FalhaEquipamentoIndividual1-4a10*/

#FalhaEquipamentoIndividual2-4a,#FalhaEquipamentoIndividual3-3a,#FalhaEquipamentoIndividual4-1a,#FalhaEquipamentoIndividual5-2a{
    display:none;
}

/*#FalhaEquipamentoIndividual1-4b11*/

#FalhaEquipamentoIndividual2-4b,#FalhaEquipamentoIndividual3-3b,#FalhaEquipamentoIndividual4-1b,#FalhaEquipamentoIndividual5-2b{
    display:none;
}

/*#FalhaEquipamentoIndividual1-4c12*/

#FalhaEquipamentoIndividual2-4c,#FalhaEquipamentoIndividual3-3c,#FalhaEquipamentoIndividual4-1c,#FalhaEquipamentoIndividual5-2c{
    display:none;
}

/*#FalhaEquipamentoIndividual1-4d13*/

#FalhaEquipamentoIndividual2-4d,#FalhaEquipamentoIndividual3-3d,#FalhaEquipamentoIndividual4-1d,#FalhaEquipamentoIndividual5-2d{
    display:none;
}



/*#FalhaEquipamentoIndividual1-4e14*/

#FalhaEquipamentoIndividual2-4e,#FalhaEquipamentoIndividual3-3e,#FalhaEquipamentoIndividual4-1e,#FalhaEquipamentoIndividual5-2e{
    display:none;
}

/*#FalhaEquipamentoIndividual6-1a73*/

#FalhaEquipamentoIndividual7-1c,#FalhaEquipamentoIndividual8-1a,#FalhaEquipamentoIndividual9-1a{
    display:none;
}

/*#FalhaEquipamentoIndividual6-1b74*/

#FalhaEquipamentoIndividual7-2b,#FalhaEquipamentoIndividual8-1b,#FalhaEquipamentoIndividual9-1b{
    display:none;
}

/*#FalhaEquipamentoIndividual6-4c84*/

#FalhaEquipamentoIndividual7-4d,#FalhaEquipamentoIndividual8-3d{
    display:none;
}

/*#FalhaEquipamentoIndividual6-4d85*/

#FalhaEquipamentoIndividual7-4c,#FalhaEquipamentoIndividual8-3c{
    display:none;
}

/*#FalhaEquipamentoIndividual6-4f87*/

#FalhaEquipamentoIndividual7-4f,#FalhaEquipamentoIndividual8-3f{
    display:none;
}

/*#FalhaEquipamentoIndividual6-5b90*/

#FalhaEquipamentoIndividual7-6b,#FalhaEquipamentoIndividual8-5b,#FalhaEquipamentoIndividual9-3b{
    display:none;
}

/*#FalhaEquipamentoIndividual6-5c91*/

#FalhaEquipamentoIndividual7-6c,#FalhaEquipamentoIndividual8-5c,#FalhaEquipamentoIndividual9-3c{
    display:none;
}

/*#FalhaEquipamentoIndividual6-6a93*/

#FalhaEquipamentoIndividual7-7a,#FalhaEquipamentoIndividual8-6a,#FalhaEquipamentoIndividual9-4a{
    display:none;
}

/*#FalhaEquipamentoIndividual6-6b94*/

#FalhaEquipamentoIndividual7-7b,#FalhaEquipamentoIndividual8-6b,#FalhaEquipamentoIndividual9-4b{
    display:none;
}

/*#FalhaEquipamentoIndividual6-7a95*/

#FalhaEquipamentoIndividual7-8a,#FalhaEquipamentoIndividual8-7a,#FalhaEquipamentoIndividual9-5a{
    display:none;
}

/*#FalhaEquipamentoIndividual6-7b96*/

#FalhaEquipamentoIndividual7-8b,#FalhaEquipamentoIndividual8-7b,#FalhaEquipamentoIndividual9-5a{
    display:none;
}


/*#FalhaEquipamentoIndividual6-8a97*/

#FalhaEquipamentoIndividual8-8a{
    display:none;
}

/*#FalhaEquipamentoIndividual6-8b98*/

#FalhaEquipamentoIndividual7-9b,#FalhaEquipamentoIndividual9-6b{
    display:none;
}

/*#FalhaEquipamentoIndividual6-8c99*/

#FalhaEquipamentoIndividual7-9c,#FalhaEquipamentoIndividual8-8c,#FalhaEquipamentoIndividual9-6c{
    display:none;
}

/*#FalhaEquipamentoIndividual6-8d100*/

#FalhaEquipamentoIndividual7-9d,#FalhaEquipamentoIndividual8-8d{
    display:none;
}

/*#FalhaEquipamentoIndividual6-9a101*/

#FalhaEquipamentoIndividual7-10a,#FalhaEquipamentoIndividual8-9a,#FalhaEquipamentoIndividual9-7a{
    display:none;
}


/*#FalhaEquipamentoIndividual6-9b102*/

#FalhaEquipamentoIndividual7-10b,#FalhaEquipamentoIndividual8-9b,#FalhaEquipamentoIndividual9-7b{
    display:none;
}


/*#FalhaEquipamentoIndividual6-9c103*/

#FalhaEquipamentoIndividual7-10c,#FalhaEquipamentoIndividual8-9c,#FalhaEquipamentoIndividual9-7c{
    display:none;
}

/*#FalhaEquipamentoIndividual6-9d104*/

#FalhaEquipamentoIndividual7-10e,#FalhaEquipamentoIndividual8-9e,#FalhaEquipamentoIndividual9-7e{
    display:none;
}

/*#FalhaEquipamentoIndividual6-10a105*/

#FalhaEquipamentoIndividual7-11a,#FalhaEquipamentoIndividual8-10a,#FalhaEquipamentoIndividual9-8a{
    display:none;
}

/*#FalhaEquipamentoIndividual6-10b106*/

#FalhaEquipamentoIndividual7-11b,#FalhaEquipamentoIndividual8-10b,#FalhaEquipamentoIndividual9-8b{
    display:none;
}

/*#FalhaEquipamentoIndividual7-3a113*/

#FalhaEquipamentoIndividual8-2a{
    display:none;
}

/*#FalhaEquipamentoIndividual7-3b114*/

#FalhaEquipamentoIndividual8-2b{
    display:none;
}

/*#FalhaEquipamentoIndividual7-3c115*/

#FalhaEquipamentoIndividual8-2c{
    display:none;
}

/*#FalhaEquipamentoIndividual6-4a82*/

#FalhaEquipamentoIndividual7-4a,#FalhaEquipamentoIndividual8-3a{
    display:none;
}

/*#FalhaEquipamentoIndividual6-4e86*/

#FalhaEquipamentoIndividual7-4e,#FalhaEquipamentoIndividual8-3e{
    display:none;
}

/*#FalhaEquipamentoIndividual7-5a123*/

#FalhaEquipamentoIndividual8-4a{
    display:none;
}

/*#FalhaEquipamentoIndividual7-5b124*/

#FalhaEquipamentoIndividual8-4b{
    display:none;
}

/*#FalhaEquipamentoIndividual7-5d126*/

#FalhaEquipamentoIndividual8-4d{
    display:none;
}

/*#FalhaEquipamentoIndividual7-5e127*/

#FalhaEquipamentoIndividual8-4e,#FalhaEquipamentoIndividual9-3e{
    display:none;
}

/*#FalhaEquipamentoIndividual7-5f128*/

#FalhaEquipamentoIndividual8-4f,#FalhaEquipamentoIndividual9-3f{
    display:none;
}

/*#FalhaEquipamentoIndividual7-10d144*/

#FalhaEquipamentoIndividual8-9d,#FalhaEquipamentoIndividual9-7d{
    display:none;
}

/*#FalhaEquipamentoIndividual7-9a137*/

#FalhaEquipamentoIndividual9-6a{
    display:none;
}


/****FalhaSistemaLancamento*****/

/*#FalhaSistemaLancamento5-60*/
#FalhaSistemaFalhaSistemaLancamento6-50,#FalhaSistemaFalhaSistemaLancamento7-70{
  display:none;
}

/*******ContatoAcidentalMergulhador*****/


/*ContatoAcidentalMergulhador 1-4g22*/

#ContatoAcidentalMergulhador2-3g{
    display:none;
}

/*ContatoAcidentalMergulhador 1-6a28*/

#ContatoAcidentalMergulhador2-3a,#ContatoAcidentalMergulhador4-3a,#ContatoAcidentalMergulhador5-2a{
    display:none;
}


/*ContatoAcidentalMergulhador 1-6b29*/

#ContatoAcidentalMergulhador2-3b,#ContatoAcidentalMergulhador4-3b{
    display:none;
}

/*ContatoAcidentalMergulhador 1-6c30*/

#ContatoAcidentalMergulhador2-3c,#ContatoAcidentalMergulhador4-3c,#ContatoAcidentalMergulhador5-2c{
    display:none;
}

/*ContatoAcidentalMergulhador 1-6d31*/

#ContatoAcidentalMergulhador2-3d,#ContatoAcidentalMergulhador4-3d,#ContatoAcidentalMergulhador5-2d{
    display:none;
}

/*ContatoAcidentalMergulhador 1-6e32*/

#ContatoAcidentalMergulhador2-3e,#ContatoAcidentalMergulhador4-3e,#ContatoAcidentalMergulhador5-2e{
    display:none;
}

/*ContatoAcidentalMergulhador 1-6f33*/

#ContatoAcidentalMergulhador2-3f,#ContatoAcidentalMergulhador4-3f,#ContatoAcidentalMergulhador5-2f{
    display:none;
}

/*ContatoAcidentalMergulhador 3-4a72*/

#ContatoAcidentalMergulhador4-4a{
    display:none;
}

/*ContatoAcidentalMergulhador 3-4b73*/

#ContatoAcidentalMergulhador4-4b{
    display:none;
}

/*ContatoAcidentalMergulhador 3-4d75*/

#ContatoAcidentalMergulhador4-4d{
    display:none;
}

/*ContatoAcidentalMergulhador 3-4c74*/

#ContatoAcidentalMergulhador4-4c{
    display:none;
}

/*ContatoAcidentalMergulhador 3-4e76*/

#ContatoAcidentalMergulhador4-4e{
    display:none;
}



/*****Form Exposição Mergulhadores Contaminantes********/

/*ExposicaoMergulhadoresContaminantes4-2a59*/

#ExposicaoMergulhadoresContaminantes6-3a{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-2b60*/

#ExposicaoMergulhadoresContaminantes6-3b{
    display:none;
}


/*ExposicaoMergulhadoresContaminantes4-2c61*/

#ExposicaoMergulhadoresContaminantes6-3c{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-2d62*/

#ExposicaoMergulhadoresContaminantes6-3d{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-2e63*/

#ExposicaoMergulhadoresContaminantes6-3e{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-6a76*/

#ExposicaoMergulhadoresContaminantes6-5a{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-6d79*/

#ExposicaoMergulhadoresContaminantes6-5d{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-6e80*/

#ExposicaoMergulhadoresContaminantes6-5e{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-6f81*/

#ExposicaoMergulhadoresContaminantes6-5f{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-7a82*/

#ExposicaoMergulhadoresContaminantes5-4a,#ExposicaoMergulhadoresContaminantes6-6a{
    display:none;
}


/*ExposicaoMergulhadoresContaminantes4-7b83*/

#ExposicaoMergulhadoresContaminantes5-4b,#ExposicaoMergulhadoresContaminantes6-6b{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-7c84*/

#ExposicaoMergulhadoresContaminantes5-4c,#ExposicaoMergulhadoresContaminantes6-6c{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-7d85*/

#ExposicaoMergulhadoresContaminantes5-4d,#ExposicaoMergulhadoresContaminantes6-6d{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-8a86*/

#ExposicaoMergulhadoresContaminantes5-5a,#ExposicaoMergulhadoresContaminantes6-7a{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-8b87*/

#ExposicaoMergulhadoresContaminantes5-5b,#ExposicaoMergulhadoresContaminantes6-7b{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-9a88*/

#ExposicaoMergulhadoresContaminantes5-6a,#ExposicaoMergulhadoresContaminantes6-8a{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-10a90*/

#ExposicaoMergulhadoresContaminantes5-7a,#ExposicaoMergulhadoresContaminantes6-9a{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-9b89*/

#ExposicaoMergulhadoresContaminantes5-6b,#ExposicaoMergulhadoresContaminantes6-8b{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-10b91*/

#ExposicaoMergulhadoresContaminantes5-7b,#ExposicaoMergulhadoresContaminantes6-9b{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-10c92*/

#ExposicaoMergulhadoresContaminantes5-7c,#ExposicaoMergulhadoresContaminantes6-9c{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-11a93*/

#ExposicaoMergulhadoresContaminantes5-8a,#ExposicaoMergulhadoresContaminantes6-10a{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-11b94*/

#ExposicaoMergulhadoresContaminantes5-8b,#ExposicaoMergulhadoresContaminantes6-10b{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-11c95*/

#ExposicaoMergulhadoresContaminantes5-8c,#ExposicaoMergulhadoresContaminantes6-10c{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-11d96*/

#ExposicaoMergulhadoresContaminantes5-8d,#ExposicaoMergulhadoresContaminantes6-10d{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-11e97*/

#ExposicaoMergulhadoresContaminantes5-8e,#ExposicaoMergulhadoresContaminantes6-10e{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes4-12b99*/

#ExposicaoMergulhadoresContaminantes5-9b{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes5-1a100*/

#ExposicaoMergulhadoresContaminantes6-1a{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes5-1b101*/

#ExposicaoMergulhadoresContaminantes6-1b{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes5-1c102*/

#ExposicaoMergulhadoresContaminantes6-1c{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes5-1d103*/

#ExposicaoMergulhadoresContaminantes6-1d{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes5-1e104*/

#ExposicaoMergulhadoresContaminantes6-1e{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes5-2a105*/

#ExposicaoMergulhadoresContaminantes6-2a{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes5-2b106*/

#ExposicaoMergulhadoresContaminantes6-2b{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes5-3a107*/

#ExposicaoMergulhadoresContaminantes6-4a{
    display:none;
}

/*ExposicaoMergulhadoresContaminantes5-3b108*/

#ExposicaoMergulhadoresContaminantes6-4b{
    display:none;
}


/****Exposicao Descargas Eletricas***/

/*ExposicaoDescargasEletricas1-5a18*/

#ExposicaoDescargasEletricas2-5a{
    display:none;
}

/*ExposicaoDescargasEletricas1-5b19*/

#ExposicaoDescargasEletricas2-5b{
    display:none;
}

/*ExposicaoDescargasEletricas1-5c20*/

#ExposicaoDescargasEletricas2-5c{
    display:none;
}

/*ExposicaoDescargasEletricas1-5d21*/

#ExposicaoDescargasEletricas2-5d,#ExposicaoDescargasEletricas12-1a{
    display:none;
}



/******Form Exposicao A Vida Marinha Nociva******/

/*ExposicaoMergulhadorVidaMarinha1-3a10*/
#ExposicaoMergulhadorVidaMarinha2-3a{
    display:none;
}

/*ExposicaoMergulhadorVidaMarinha1-3b11*/
#ExposicaoMergulhadorVidaMarinha2-3b{
    display:none;
}

/*ExposicaoMergulhadorVidaMarinha1-5b15*/
#ExposicaoMergulhadorVidaMarinha2-4b{
    display:none;
}

/*ExposicaoMergulhadorVidaMarinha2-1c19*/
#ExposicaoMergulhadorVidaMarinha2-2a{
    display:none;
}


/*******Form Emergencias Medicas Hiberbaricas********/


/*EmergenciasMedicasHiberbaricas1-1a1*/

#EmergenciasMedicasHiberbaricas2-1a,#EmergenciasMedicasHiberbaricas3-1a{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-1a2*/

#EmergenciasMedicasHiberbaricas2-1b,#EmergenciasMedicasHiberbaricas3-1b{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-1c3*/

#EmergenciasMedicasHiberbaricas2-1c,#EmergenciasMedicasHiberbaricas3-1c{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-1e5*/

#EmergenciasMedicasHiberbaricas2-1f,#EmergenciasMedicasHiberbaricas3-1e{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-2c8*/

#EmergenciasMedicasHiberbaricas4-7a{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-2e10*/

#EmergenciasMedicasHiberbaricas1-8g,#EmergenciasMedicasHiberbaricas2-9g,#EmergenciasMedicasHiberbaricas3-6h,#EmergenciasMedicasHiberbaricas4-7e{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-3a11*/

#EmergenciasMedicasHiberbaricas2-2a{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-3c13*/

#EmergenciasMedicasHiberbaricas2-2b{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-3d14*/

#EmergenciasMedicasHiberbaricas2-2c{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-3g17*/

#EmergenciasMedicasHiberbaricas2-2e{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-3h18*/

#EmergenciasMedicasHiberbaricas2-2f{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-4a19*/

#EmergenciasMedicasHiberbaricas2-5a,#EmergenciasMedicasHiberbaricas3-2a{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-4b20*/

#EmergenciasMedicasHiberbaricas2-5b,#EmergenciasMedicasHiberbaricas3-2b{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-4c21*/

#EmergenciasMedicasHiberbaricas2-5c,#EmergenciasMedicasHiberbaricas3-2c,#EmergenciasMedicasHiberbaricas4-3b{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-4e23*/

#EmergenciasMedicasHiberbaricas2-5d,#EmergenciasMedicasHiberbaricas3-2e,#EmergenciasMedicasHiberbaricas4-3c{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-5a25*/

#EmergenciasMedicasHiberbaricas2-3a,#EmergenciasMedicasHiberbaricas3-4a{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-5c27*/

#EmergenciasMedicasHiberbaricas2-3c,#EmergenciasMedicasHiberbaricas3-4c{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-5d28*/

#EmergenciasMedicasHiberbaricas2-3d,#EmergenciasMedicasHiberbaricas3-4d{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-5e29*/

#EmergenciasMedicasHiberbaricas3-4c{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-6a30*/

#EmergenciasMedicasHiberbaricas2-4a,#EmergenciasMedicasHiberbaricas3-5a{
    display:none;
}


/*EmergenciasMedicasHiberbaricas1-6c32*/

#EmergenciasMedicasHiberbaricas2-4c,#EmergenciasMedicasHiberbaricas3-5c{
    display:none;
}


/*EmergenciasMedicasHiberbaricas1-6d33*/

#EmergenciasMedicasHiberbaricas2-4d,#EmergenciasMedicasHiberbaricas3-5d{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-6e34*/

#EmergenciasMedicasHiberbaricas2-4e,#EmergenciasMedicasHiberbaricas3-5e{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-8b40*/

#EmergenciasMedicasHiberbaricas2-9b,#EmergenciasMedicasHiberbaricas3-6c{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-8c41*/

#EmergenciasMedicasHiberbaricas2-9c,#EmergenciasMedicasHiberbaricas3-6d{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-8d42*/

#EmergenciasMedicasHiberbaricas2-9d,#EmergenciasMedicasHiberbaricas3-6e{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-8e43*/

#EmergenciasMedicasHiberbaricas2-9e,#EmergenciasMedicasHiberbaricas3-6f{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-10c50*/

#EmergenciasMedicasHiberbaricas4-8d{
    display:none;
}


/*EmergenciasMedicasHiberbaricas1-10d51*/

#EmergenciasMedicasHiberbaricas4-8e{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-11a52*/

#EmergenciasMedicasHiberbaricas2-6a,#EmergenciasMedicasHiberbaricas3-8a#EmergenciasMedicasHiberbaricas4-5a{
    display:none;
}



/*EmergenciasMedicasHiberbaricas1-11b53*/

#EmergenciasMedicasHiberbaricas2-6b,#EmergenciasMedicasHiberbaricas3-8b#EmergenciasMedicasHiberbaricas4-5b{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-11c54*/

#EmergenciasMedicasHiberbaricas2-6c,#EmergenciasMedicasHiberbaricas3-8c#EmergenciasMedicasHiberbaricas4-5c{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-11d55*/

#EmergenciasMedicasHiberbaricas2-6d{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-12a57*/

#EmergenciasMedicasHiberbaricas2-10a,#EmergenciasMedicasHiberbaricas3-9a,#EmergenciasMedicasHiberbaricas4-9a{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-12b58*/

#EmergenciasMedicasHiberbaricas2-10b,#EmergenciasMedicasHiberbaricas3-9b,#EmergenciasMedicasHiberbaricas4-9b{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-12c59*/

#EmergenciasMedicasHiberbaricas2-10c,#EmergenciasMedicasHiberbaricas3-9c,#EmergenciasMedicasHiberbaricas4-9c{
    display:none;
}

/*EmergenciasMedicasHiberbaricas1-12d60*/

#EmergenciasMedicasHiberbaricas3-9d,#EmergenciasMedicasHiberbaricas4-9d{
    display:none;
}

/*EmergenciasMedicasHiberbaricas3-8d150*/

#EmergenciasMedicasHiberbaricas4-5d{
    display:none;
}

/*EmergenciasMedicasHiberbaricas4-2b162*/

#EmergenciasMedicasHiberbaricas4-6c{
    display:none;
}


/*EmergenciasMedicasHiberbaricas4-8b189*/

#EmergenciasMedicasHiberbaricas4-8c{
    display:none;
}



































