#form-diagram{
    background-color:white ;
    font-size: 15px;
    margin: 60px;
    padding: 50px;
}

#form-diagram select{
    width:170px;
    margin-left: 10px;
}

#LabelUser{
    margin-left: 10px;
}

#name-user{
    margin:20px;
}
#form-diagram .btn,#updateButton{

padding: 15px;
font-size: 14px;
font-weight: bold;
border-radius: 10px;
}


#form-diagram h2{

    text-align: center;
    height: 40px;
    border-radius: 10px;
    padding: 5px;
    margin-top:10px;
    margin-bottom: 10px;
}

#ResultButton{
    padding: 10px;
font-size: 14px;
margin-left: 40px;
margin-top: 40px;
}

.Block-Diagram{
    margin-top: 130px;
    width:210rem;
    font-size: 14px;
}



#FirstBox1,#FirstBox2,#FirstBox3,#FirstBox4,#FirstBox5,#FirstBox6,#FirstBox7,#FirstBox8,#FirstBox9,#FirstBox10,#FirstBox11{
    border-top: 5px solid #343194;
    border-left:5px solid #343194;
    border-right: 5px solid #343194;
    border-bottom:30px solid #343194;
    padding: 20px;
    border-radius: 10px;
    width:178px;
    text-align: center;

}

#FirstBox1{
    margin-right:180px;
    position: relative;
left: 178px;

}

#FirstBox2{
    margin-right:120px;
    position: relative;
left: 118px;
}


#FirstBox4{
    margin-right: 240px;
    position: relative;
left: 244px;
}

#FirstBox5{
    margin-right: 240px;
position: relative;
left:  302px;
}

#FirstBox6{
    margin-right: 240px;
position: relative;
left: 364px;
}


#child-first-box{
    border: 1px solid #343194;
    padding-top: 12px;
    border-radius: 20px;
    margin-left: 10px;
    height: 70px;
    width: 77px;
    margin-top: 10px;
}

#SecondBox1,#SecondBox2,#SecondBox3,#SecondBox4{
    border-top: 5px solid #b8291a;
    border-left:5px solid #b8291a;
    border-right: 5px solid #b8291a;
    border-bottom:30px solid #b8291a;
    padding: 40px 20px 20px 20px;
    border-radius: 20px;
    width:160px;
    height:22rem;
    text-align: center;
}

#child-second-box{
    border: 1px solid #b8291a;
    padding: 35px;
    border-radius: 20px;
    margin-left: 10px;
}




.answer-box1{
    height: 30px;
    border: 1px solid;
    border-radius: 10px;
    margin-left: 10px;
    width: 50px;
    position: relative;
    top: 45px;
    text-align: center;
    font-weight: bold;
}

.answer1{
    left: 67px;
}

.answer2{
    top:132px;
    left: 66px;
}

.answer3{
    top:66px;
}

.answer4{
    top:100px;
    left: 117px;
}

.answer5{
    left: 59px;
    top: 62px;
}

.answer6{
    top: 55px;
left: 120px;
}

.answer-box2{
    height: 30px;
    border: 1px solid;
    border-radius: 10px;
    margin-left: 10px;
    width: 64px;
    position: relative;
    top: 110px;
    text-align: center;
    font-weight: bold;
}

.answer-box2CO4Form1{
    top:-33px;
}

.AM{
    width: 100rem;
margin-top: 62px;
position: relative;
right: 60px;
margin-left: 100px;
}



.CO{
    margin-left: 15rem;
}

.CO.COForm2{
    position: relative;
top: 66px;
}

#Event-Block{
    margin-left: 110px;
}

#Hazard,#HazardForm2,#HazardForm3,#HazardForm4,#HazardForm5,#HazardForm6,#HazardForm7,#HazardForm8,#HazardForm9,#HazardForm10,#HazardForm11,#HazardForm12{
    width: 190px;
height: 130px;
padding-top: 45px;
margin-top: 5px;
position: relative;
bottom: 100px;
border-radius: 20px;
text-align: center;
background-image: linear-gradient(45deg, #805a0e 25%, #db9a16 25%, #db9a16 50%, #805a0e 50%, #805a0e 75%, #db9a16 75%, #db9a16 100%);
background-size: 56.57px 56.57px;
}

/*CSS Events*/
#Event,#EventForm2,#EventForm3,#EventForm4,#EventForm5,#EventForm6,#EventForm7,#EventForm8,#EventForm9,#EventForm10,#EventForm11,#EventForm12{
    border: 25px solid #d42626;
width: 190px;
height: 160px;
padding-top: 40px;
margin-top: 120px;
border-radius: 65px;
text-align: center;
}

#EventForm5{
    height:211px;
}

#EventForm6{
    height: 235px;
}



#Button-Collapse1{
    height: 30px;
position: relative;
top: 30px;
}

#card-collapse{
    width: 50px;
    position: relative;
    top: 30px;
    margin-left: 10px;
    border-radius: 10px;
    height: 30px;
}

#legend{
    width: 400px;
    position: relative;
    left: 110rem;
    bottom: 1rem;
}

#table-legend{
    width:328px;
}

#table-legend-AM{
    width:328px;
}

#CO-link{
    width: 329px;
}

#Table-Legend-Block,#Table-Legend-Block-Acidentes-Hiperbaricos{
   margin:10px;
}

#Table-Legend-Block .d-flex,#Table-Legend-Block-Acidentes-Hiperbaricos .d-flex{
    margin-bottom: 10px;
}

#Title-Hazard{
    background: white;
margin: 5px;
padding-top: 10px;
padding-bottom: 10px;
border-radius: 10px;
}




.hBrfIz{
    background:white !important;
}


#Select-Embarcacao{
    width: 180px;
font-size: 12px;
margin: 20px
}



.label-date{
    font-size: 15px;
margin-top: 20px;
}

#form-embarcacao button{
    width: 100px;
height: 30px;
font-size: 15px;
font-weight: bold;
margin-top: 20px;
}


/*Login Page*/

#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    background-image: linear-gradient(#787cfa,#5053a3);
  }



/*Falta Equipamento Respiratório*/

#AM1 #FirstBox1{
    left:250px;
}

#AM2 #FirstBox2{
    left: 189px;
}

#AM3 #FirstBox3{
    left: 359px;
}

  /*CSS Acidentes Hiperbaricos*/

  #AM2Form2{
    position: relative;
    left: 191px;
  }

  #AM3Form2{
    position: relative;
    left: 197px;
  }


  #AM4Form2{
    position: relative;
right: 45px;
  }

  #AM5Form2{
    position: relative;
right: -15px;
  }
  #AM6Form2{
    position: relative;
left: 197px;
  }

  #AM7Form2{
    position: relative;
    left: 193px;
  }

  #AM8Form2{
    position: relative;
left: 196px;
  }

  #AM9Form2{
    position: relative;
left: 201px;
  }

  .SecondBox1Form2{
    top: 185px;
    position: relative;
  }

  .answer-box2Form2{
      top:252px;
  }



/*Falha no Sistema de Lançamento*/

#AM1Form3 #FirstBox1{
    left:241px;
}

.answer1Form3{
    left:58px;
}


#AM3Form3 #FirstBox3{
    left:244px;
}


.answer3Form3{
    left:243px;
}

#AM4Form3 #FirstBox4{
    left:186px;
}


.answer4Form3{
    left: -59px;
}

/*Aprisionamento Mergulhador*/

#AM3Form4 #FirstBox3{
    left: 355px;
    position: relative;
}

.answer3Form4{
    left:361px;
}

#AM4Form4 #FirstBox4{
    position: relative;
left: 346px;
}

.answer4Form4{
    left:112px;
}

#AM5Form4 #FirstBox5{
    position: relative;
left: 413px;
}

.answer5Form4{
    left:174px;
}


#AM6Form4 #FirstBox6{
    left: 176px;
}

.answer6Form4{
    right:58px;
}

#AM7Form4 #FirstBox7{
    left: 176px;
position: relative;
}

.answer7Form4{
    left:181px;
}

.SecondBox1Form4{
    top: 250px;
position: relative;
}
.answer-box2Form4{
    top:320px;
}
/*Exposiçao Inadequada Mergulhador*/

#AM2Form5 #FirstBox2{
    left:180px;
}
.answer2Form5{
    left:58px;
}

#AM3Form5 #FirstBox3{
    position: relative;
    left:482px;
}

.answer3Form5{
    left:478px;
}

#AM4Form5 #FirstBox4{
    left:359px;
}

.answer4Form5{
    left:118px;
}


/*Incendio Explosao Camara*/

#AM2Form6 #FirstBox2{
    left:412px;
}

.answer2Form6{
    left:295px;
}

#AM3Form6 #FirstBox3{
    left: 292px;
position: relative;
}

.answer3Form6{
    left:295px;
}


.answer4Form6{
    left:55px;
}

.answer5Form6{
    left:55px;
}


.answer6Form6{
    left:115px;
}

/*Falha Equipamento Individual*/

#AM1Form7 #FirstBox1{
    left:246px;
}

.answer1Form7{
    left:63px;
}

#AM2Form7 #FirstBox2{
    left:242px;
}

.answer2Form7{
    left:122px;
}

#AM3Form7 #FirstBox3{
    left: 303px;
position: relative;
}

.answer3Form7{
    left:301px;
}

#AM5Form7 #FirstBox5{
    left:244px;
}

.answer-box2CO4Form7{
    top:-33px;
}


/*Contato Acidental Mergulhador*/

#Event8{
    height: 180px;
}

#AM2Form8 #FirstBox2{
    left:300px;
}

.answer2Form8{
    left:180px;
}

#AM3Form8 #FirstBox3{
    left: 361px;
position: relative;
}

.answer3Form8{
    left:360px;
}

#AM4Form8 #FirstBox4{
    left: 367px;
}

.answer4Form8{
    left:123px;
}

#AM5Form8 #FirstBox5{
    left: 428px;
}

.answer5Form8{
    left:184px;
}

.SecondBox1Form8{
    position: relative;
top: 249px;
}

.answer-box2Form8{
    top:318px;
}




/*Exposição dos Mergulhadores aos Contaminantes*/

#EventForm9{
    height:190px;
}

#AM2Form9 #FirstBox2{
    left:304px;
}

.answer2Form9{
    left:182px;
    top: 21px;
}

#AM3Form9 #FirstBox3{
    left: 244px;
position: relative;
}

.answer3Form9{
    left:241px;
}

/*Form Exposicão Descargas Eletricas*/

#HazardForm10{
    width: 205px;
}

#EventForm10{
    height: 328px;
width: 208px;
}

#AM3Form10 #FirstBox3{
    left: 115px;
position: relative;
}

.answer3Form10{
    left:120px;
}

#AM4Form10 #FirstBox4{
    left: 182px;
position: relative;
}

.answer4Form10{
    left:-61px;
}

#AM5Form10 #FirstBox5{
    left: 421px;

}

.answer5Form10{
    left:179px;
}

#AM6Form10 #FirstBox6{
    left: 185px;

}

.answer6Form10{
    left:-60px;
}

#AM7Form10 #FirstBox7{
    left: 367px;
position: relative;

}

.answer7Form10{
    left:362px;
}


#AM8Form10 #FirstBox8{
    left: 426px;
    position: relative;

}

.answer8Form10{
    left:423px;
}

#AM9Form10 #FirstBox9{
    left: 243px;
    position: relative;

}

.answer9Form10{
    left:243px;
}

#AM10Form10 #FirstBox10{
    left: 304px;
    position: relative;

}


.answer10Form10{
    left:302px;
}

#AM11Form10 #FirstBox11{
    left: 308px;
position: relative;

}

.answer11Form10{
    left:312px;
}

.SecondBox1Form10{
    position: relative;
top: 318px;
}

.answer-box2Form10{
    top: 403px;
}




/*Form Exposição Vida Marinha Nociva*/
#EventForm11{
    height:190px;
}


#AM2Form11 #FirstBox2{
    left: 175px;
}

.answer2Form11{
    left:56px;
    top:63px;
}

.SecondBox1Form11{
    height: 27rem !important;
position: relative;
top: 236px;
}

.answer-box2Form11{
    top:334px;
}


/*Form  EmergenciasMedicasHiberbaricas*/

#EventForm12{
    height:190px;
}

#AM2Form12 #FirstBox2{
    left: 293px;
}

.answer2Form12{
    left: 171px;
}

#AM3Form12 #FirstBox3{
    left: 350px;
position: relative;
}

.answer3Form12{
    left: 352px;
}

#AM4Form12 #FirstBox4{
    left: 360px;
}

.answer4Form12{
    left: 116px;
}

.answer-box2Form12{
    top:335px;
}

.SecondBox1Form12{
    top: 246px;
position: relative;
}
