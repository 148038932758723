.form-check{
    display:flex;
    width:100%;
}

#inputLocalAtividade,#selectall{
    height:20px !important;
    width:20px !important;
}

.form-check label{
    margin:5px;
}